import React from "react";
import { ReactComponent as Logo } from "../svg/logo.svg";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import Resume from "../docs/resume.doc";

function Footer() {
  const footerYear = new Date().getFullYear();
  return (
    <footer className="bg-accent flex justify-between fixed  bottom-0 left-0 right-0">
      <div className="flex flex-col pr-3">
        <h4 className="pt-2 pl-1">Short on time?</h4>
        <div className="flex flex-row p-1 text-2xl">
          <a
            className="pr-5 self-center"
            href="https://www.linkedin.com/in/softwaredevjordan/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaLinkedin />
          </a>
          <a
            className="pr-5 self-center"
            href="https://github.com/softwaredevjordan"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaGithub />
          </a>
          <a className="self-center" href={Resume} download>
            Resume
          </a>
        </div>
      </div>
      <div className="pr-2 pl-2">
        <Logo height="70px" width="70px" className="m-auto" />
        <p className="text-xs p-1">&copy; {footerYear} SoftwareDevJordan</p>
      </div>
    </footer>
  );
}

export default Footer;
