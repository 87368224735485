import React, { useEffect, useState } from "react";
import { FaReact, FaTabletAlt, FaEdit } from "react-icons/fa";
import Hero from "./Hero";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../svg/logo.svg";
function Nav() {
  const location = useLocation();
  return (
    <div className="relative ">
      <div className="navbar bg-base-100 shadow-md mb-4 sm:pr-1">
        <div className="flex-auto ">
          <Link to="/" className="btn btn-ghost ">
            <Logo
              height="50px"
              width="50px"
              className="self-stretch box-border "
            />
          </Link>
        </div>
        <div className="flex-col-reverse shrink ">
          <ul className="menu menu-horizontal text-sm md:text-xl md:pr-5">
            <li className="md:mr-5 md:ml-5">
              <Link to="/about" className="p-1">
                <FaTabletAlt className="text-accent" size={20} />
                About
              </Link>
            </li>
            <li className="md:mr-5 md:ml-5">
              <Link to="/projects" className="p-1 ">
                <FaReact className="text-accent " size={20} />
                Projects
              </Link>
            </li>
            <li className="mr-1 md:mr-5 md:ml-5">
              <Link to="/contact" className="p-1">
                <FaEdit className="text-accent" size={20} />
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Nav;
//  primary: "#a991f7",
//           secondary: "#f6d860",
//           accent: "#37cdbe",
//           neutral:
